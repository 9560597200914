import React, { Fragment } from "react";
import styled from "styled-components";

import Seo from "../components/seo";

const Current = styled.div`
  max-width: 60ch;
`;

const Index = () => {
  return (
    <Fragment>
      <Seo title="Home" />
      <Current>
        <p>
          Brooke Nicholas is an independent curator based in New York City.
          <br />
        </p>
      </Current>
    </Fragment>
  );
};

export default Index;
